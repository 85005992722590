import React from "react";
import DevOpsOffer1 from "@images/dev-ops/DevOps-offer.svg";
import DevOpsOffer2 from "@images/dev-ops/DevOps-offer2.svg";
import DevOpsOffer3 from "@images/dev-ops/DevOps-offer3.svg";
import DevOpsOffer4 from "@images/dev-ops/DevOps-offer4.svg";
import DevOpsOffer5 from "@images/dev-ops/DevOps-offer5.svg";
import DevOpsOffer6 from "@images/dev-ops/DevOps-offer6.svg";
import DevOpsOffer7 from "@images/dev-ops/DevOps-offer7.svg";
import loadable from "@loadable/component";
import { OffersSectionContainer } from "./OffersSection.style";

const BenefitsListSection = loadable(() =>
    import("@common/CommonSections/BenefitsListSection/BenefitsListSection")
);

const listItems = [
    {
        title: "Cloud Services",
        icon: DevOpsOffer1,
        description: `
            management and maintenance of the infrastructure needed to deliver cloud services 
            (servers, storage, databases, software, and analytics). Examples of cloud services 
            include software as a service (SaaS), platform as a service (PaaS), and infrastructure 
            as a service (IaaS).
        `,
    },
    {
        title: "Security, Identity & Compliance",
        icon: DevOpsOffer5,
        description: `
            incorporation and maintenance of tools and techniques to manage security, identity, and compliance,
            including security testing tools, access management systems, and compliance frameworks.
        `,
    },
    {
        title: "Continuous Integration and Delivery (CI/CD)",
        icon: DevOpsOffer2,
        description: `
            CI/CD helps to ensure that new code changes are delivered quickly, reliably, and with minimal risk of defects.
            CI/CD is essential for teams that want to improve their software development processes
            and deliver high-quality software faster.
        `,
    },
    {
        title: "Storage & Network Delivery",
        icon: DevOpsOffer6,
        description: `
            management of storage technologies and network delivery technologies and services such as cloud storage, 
            network-attached storage (NAS), storage area networks (SANs), load balancers, content delivery networks (CDNs), 
            and application delivery controllers (ADCs).
        `,
    },
    {
        title: "Environment Configuration",
        icon: DevOpsOffer3,
        description: `
            configuration and management the various environments that are used throughout 
            the software development lifecycle. This includes development, testing, staging,
            and production environments.
        `,
    },
    {
        title: "Monitoring & Alerting",
        icon: DevOpsOffer7,
        description: `
            continuous collection and analysis of data from various sources and automatic generation of notifications 
            when issues are detected. Adopting DevOps as a service ensures that issues are detected and resolved quickly.
        `,
    },
    {
        title: "Container Orchestrators",
        icon: DevOpsOffer4,
        description: `
            managing orchestrators helps DevOps teams to manage large numbers of containers across multiple
            hosts or clusters, and to ensure that these containers are deployed and scaled efficiently.
        `,
    },
];

const OffersSection = () => (
    <OffersSectionContainer>
        <BenefitsListSection
            listItems={listItems}
            heading="Our DevOps services"
        />
    </OffersSectionContainer>
);

export default OffersSection;
